:root {
    --visited: #CE88F9;
    --shortest: #ffffff;
    --transition: #ffcccc;
}

.node {
    padding: 0;
}

.nodeStart {
    /* background-color: green; */
    background: url(../../resources/start.png);
}

.nodeFinish {
    /* background-color: red; */
    background: url(../../resources/finish.png);
}

.nodeWall {
    background: url(../../resources/wall.png);
    /* background-color: rgba(245, 245, 245, 0.445); */
}

.shortestPath {
    animation-name: path;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.visited {
    background-color: var(--visited);
}

.visited-anim {
    animation: onvisit 0.5s;
    background-color: var(--visited);
}

.shortestPath-anim {
    animation-name: path;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes path {
    from {
      background-color: rgba(255, 255, 255, 0.8);
    }
    to {
      background: url(../../resources/send.svg);
      background-size: contain;
      background-color: rgba(0, 255, 115, 0.8);
    }
  }
