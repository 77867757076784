.grid {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
}

.grid td {
    /* border: 0.1px solid rgba(7, 7, 7, 0.40); */
    /* border: 0.1px solid rgba(186, 90, 255, 0.25); */
    border: 0.1px solid rgba(145, 79, 186, 0.30);
    width: 1fr;
    height: 1fr;
    padding: 0;
}

