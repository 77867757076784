:root {
    box-sizing: border-box;
}

.app {
    display: flex;
}

.content {
    display: flex;
    width: 100%;
    --margin-top: 7vh;
    padding: 1vw 1vw 1vw 1vw;
}

.data {
    width: 40%;
    height: auto;
}

.dataContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1400px) {
    .content {
        flex-direction: column;
    }
    .data {
        width: 100%;
        height: 200px;
        margin-top: 2vh;
    }
    .dataContent {
        flex-direction: row;
    }
}

.nodeWall {
    background: url(./resources/wall.png);
    /* background-color: rgba(255, 254, 254, 0.829); */
  }
  
  .visited-anim {
    animation: onvisit 0.5s;
    background-color: var(--visited);
  }
  .visited {
    background-color: var(--visited);
  }
  .shortestPath-anim {
    animation-name: path;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes path {
    from {
      background-color: rgba(255, 255, 255, 0.8);
    }
    to {
      background: url(./resources/send.svg);
      background-size: contain;
      background-color: rgba(94, 255, 137, 0.8);
    }
  }

  
  .shortestPath {
    animation-name: path;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

  @keyframes onvisit {
    0% {
      background-color: var(--transition);
    }
    100% {
      background-color: var(--visited);
    }
  }